import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Full Clean 5×1+1 \\@90% 1RM`}</p>
    <p>{`then, for time:`}</p>
    <p>{`10/9/8/7/6/5/4/3/2/1 Power Cleans (155/105)`}</p>
    <p>{`30 Double Unders after every set`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The signup sheet for Memorial Day Murph is up next to the white
board at the gym.  We’ll have heats starting every :30 beginning at
9:00am.  We’ll have a potluck/cookout afterward, around 12:00.  Last
names beginning in A-M please bring a side dish, N-Z a dessert.  CJ
Ransom & Seven Seas Construction has made a donation to our Memorial Day
event so all meat for grilling will be provided as well!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      